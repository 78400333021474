






































.history-page {
  width: 100%;
  position: relative;
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 0;
    min-height: 40px;
    .navbar-link {
      width: 100%;
      max-width: 160px;
      min-width: 100px;
      height: 100%;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child{
          margin-left: 0;
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #999999;
        cursor: pointer;
        text-decoration: none;
        &.active , &:hover{
          background: #008CFF;
        }

        .text {
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          text-transform: uppercase;
        }
        .icon {
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
  }
  .content-box {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0rem 0;
  }
}
